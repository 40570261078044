<template>
  <div>
    <AlertEmails
      ref="alertEmails"
      :emails="emailsGame"
      :game="typeform"
      @saveEmails="saveEmails"
    />
    <form @submit.prevent="save()">
      <b-row>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t('roulettes.form.rouletteFisic') }}</label>
            <div>
              <select
                v-model="typeform.providerId"
                name="providerId"
                class="form-select border-0 shadow-lg"
                :class="{
                  'is-invalid': typesubmit && $v.typeform.providerId.$error,
                }"
                placeholder="Seleccione.."
                ref="rouletteFisicSelect"
                @change="handleChange"
              >
                <option disabled selected>{{ $t('helpers.select') }}...</option>
                <option
                  v-for="(item, index) in rouletteFisics"
                  :key="index"
                  :value="item.providerId"
                  :selected="typeform.providerId == item.providerId"
                >
                  {{ item.name }}
                </option>
              </select>
              <div
                v-if="typesubmit && $v.typeform.providerId.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.typeform.providerId.required">
                  Este campo es requerido.
                </span>
              </div>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>TIPO DE RULETA</label>
            <div>
              <select
                v-model="typeform.numbersDistribution"
                name="DISTRIBUTION"
                class="form-select border-0 shadow-lg"
                placeholder="Seleccione.."
                ref="rouletteFisicSelect"
              >
                <option disabled selected>{{ $t('helpers.select') }}...</option>
                <option
                  v-for="(item, index) in rouletteTypes"
                  :key="index"
                  :value="item"
                  :selected="typeform.numbersDistribution == item"
                >
                  {{ item }}
                </option>
              </select>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Nombre</label>
            <vs-input
              v-model="typeform.name"
              placeholder="Nombre"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.name.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.name.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.name.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label> Logo</label>
            <vs-input
              v-model="typeform.logo"
              placeholder="Logo"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.logo.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.logo.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.logo.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t('roulettes.form.pleno') }}</label>
            <vs-input
              v-model="typeform.pleno"
              :placeholder="$t('roulettes.form.pleno')"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.pleno.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.pleno.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.pleno.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t('roulettes.form.semipleno') }}</label>
            <vs-input
              v-model="typeform.semipleno"
              :placeholder="$t('roulettes.form.semipleno')"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.semipleno.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.semipleno.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.semipleno.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t('roulettes.form.calle3') }}</label>
            <vs-input
              v-model="typeform.calle"
              :placeholder="$t('roulettes.form.calle3')"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.calle.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.calle.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.calle.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>

        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t('roulettes.form.calle4') }}</label>
            <vs-input
              v-model="typeform.cuadro"
              :placeholder="$t('roulettes.form.calle4')"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.cuadro.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.cuadro.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.cuadro.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t('roulettes.form.calle6') }}</label>
            <vs-input
              v-model="typeform.linea"
              :placeholder="$t('roulettes.form.calle6')"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.linea.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.linea.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.linea.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6" v-if="typeform.specialCalle || showSpecialCalle">
          <div class="mb-3">
            <label>{{ $t('roulettes.form.specialCalle') }}</label>
            <vs-input
              v-model="typeform.specialCalle"
              :placeholder="$t('roulettes.form.specialCalle')"
              border
              class="shadow-lg"
            />
          </div>
        </b-col>

        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t('roulettes.form.column') }}</label>
            <vs-input
              v-model="typeform.columna"
              :placeholder="$t('roulettes.form.column')"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.columna.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.columna.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.columna.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t('roulettes.form.dozen') }}</label>
            <vs-input
              v-model="typeform.docena"
              :placeholder="$t('roulettes.form.dozen')"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.docena.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.docena.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.docena.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t('roulettes.form.chanceSimple') }}</label>
            <vs-input
              v-model="typeform.chanceSimple"
              :placeholder="$t('roulettes.form.chanceSimple')"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.chanceSimple.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.chanceSimple.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.chanceSimple.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t('roulettes.form.cubre') }}</label>
            <vs-input
              v-model="typeform.cubre"
              :placeholder="$t('roulettes.form.cubre')"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.cubre.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.cubre.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.cubre.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t('form.minBet') }}</label>
            <vs-input
              v-model="typeform.minBet"
              :placeholder="$t('form.minBet')"
              border
              class="shadow-lg"
            />
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <label>{{ $t('form.maxBet') }}</label>
          <vs-input
            v-model="typeform.maxBet"
            :placeholder="$t('form.maxBet')"
            border
            class="shadow-lg"
          />
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>
              {{ $t('form.betByPosition') }}:
              <span v-if="typeform.maxBetPosition && typeform.maxBet"
                >{{ (typeform.maxBetPosition * 100) / typeform.maxBet }}%</span
              ></label
            >
            <vs-input
              v-model="typeform.maxBetPosition"
              :placeholder="$t('configLimits.betByPosition')"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.maxBetPosition.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.maxBetPosition.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.maxBetPosition.numeric">
                Este campo debe ser númerico.
              </span>
              <span v-if="!$v.typeform.maxBetPosition.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label> Max. apuestas a plenos permitidos por ronda: </label>
            <vs-input
              v-model="typeform.maxPlenosBet"
              placeholder="Ej. 24"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.maxPlenosBet.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.maxPlenosBet.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.maxPlenosBet.numeric">
                Este campo debe ser númerico.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Color:</label>
            <vs-input
              v-model="typeform.color"
              type="color"
              placeholder="Color"
              border
              class="shadow-lg"
            />
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label> Imagen de fondo transmision </label>
            <vs-input
              v-model="typeform.imgBackground"
              placeholder="Imagen de fondo"
              border
              class="shadow-lg"
            />
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Lenguaje de la ruleta</label>
            <div>
              <select
                v-model="typeform.language"
                name="providerId"
                class="form-select border-0 shadow-lg"
                :class="{
                  'is-invalid': typesubmit && $v.typeform.language.$error,
                }"
                placeholder="Seleccione.."
                ref="rouletteFisicSelect"
                @change="handleChange"
              >
                <option disabled selected>{{ $t('helpers.select') }}...</option>
                <option
                  v-for="item in languages"
                  :key="item.value"
                  :value="item.value"
                  :selected="typeform.providerId == item.providerId"
                >
                  {{ item.name }}
                </option>
              </select>
              <div
                v-if="typesubmit && $v.typeform.language.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.typeform.language.required">
                  Este campo es requerido.
                </span>
              </div>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Link Live Video</label>
            <vs-input
              v-model="typeform.urlTransmision"
              type="text"
              class="shadow-lg"
              name="urlTransmision"
              :class="{
                'is-invalid': typesubmit && $v.typeform.urlTransmision.$error,
              }"
            />
          </div>
          <div
            v-if="typesubmit && $v.typeform.urlTransmision.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.typeform.urlTransmision.required">
              Este campo es requerido.
            </span>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Repeticion de resultados para enviar alerta</label>
            <vs-input
              v-model="typeform.maxRepeatedResults"
              placeholder="2"
              type="number"
              class="shadow-lg"
              name="maxRepeatedResults"
              :class="{
                'is-invalid':
                  typesubmit && $v.typeform.maxRepeatedResults.$error,
              }"
            />
          </div>
          <div
            v-if="typesubmit && $v.typeform.maxRepeatedResults.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.typeform.maxRepeatedResults.required">
              Este campo es requerido.
            </span>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <label>BANCO DE JACKPOTS</label>
          <vs-input
            v-model="typeform.bank"
            placeholder="1000"
            type="number"
            name="jackpotBank"
            step="any"
            class="shadow-lg"
          />
        </b-col>
        <b-col sm="12" md="6">
          <label>BANCO INICIAL</label>
          <vs-input
            v-model="typeform.initialBank"
            placeholder="3000"
            type="number"
            name="initialBank"
            step="any"
            class="shadow-lg"
          />
        </b-col>
        <b-col sm="12" md="6">
          <label>BANCO MAXIMO</label>
          <vs-input
            v-model="typeform.maximunBank"
            placeholder="5000"
            type="number"
            name="maximunBank"
            step="any"
            class="shadow-lg"
          />
        </b-col>
        <b-col sm="12" md="6">
          <label>Maximo de Jackpots</label>
          <multiselect
            v-model="typeform.numbersOfJackpot"
            :options="numbersOfJackpot"
          >
          </multiselect>
        </b-col>
        <b-col sm="12" md="6">
          <label>Version de Jackpot</label>
          <multiselect
            v-model="typeform.jackpotVersion"
            :options="jackpotVersions"
          >
          </multiselect>
        </b-col>
        <b-col sm="12" md="6">
          <label>Jackpots permitidos</label>
          <multiselect
            v-model="typeform.multisAllowed"
            :options="multisAllowedRef"
            :multiple="true"
          >
          </multiselect>
        </b-col>
        <b-col sm="12" md="6">
          <vs-button type="button" @click="modifyAlertEmails">
            EMAILS DE ALERTA
          </vs-button>
        </b-col>
        <b-col sm="12" md="6">
          <vs-checkbox v-model="typeform.jackpotRandom">
            JACKPOT RANDOM
          </vs-checkbox>
        </b-col>
        <b-col sm="12" md="6">
          <vs-checkbox v-model="typeform.isManualRoulette">
            RULETA MANUAL
          </vs-checkbox>
        </b-col>
        <b-col sm="12" md="6">
          <vs-checkbox v-model="typeform.isShow">
            RULETA PARA EXHIBICION
          </vs-checkbox>
        </b-col>
        <b-col sm="12" md="6">
          <vs-tooltip>
            <template #tooltip>
              Esta ruleta no se apagará automaticamente
            </template>
            <vs-checkbox v-model="typeform.alwaysOpen">
              RULETA 24 Hrs
            </vs-checkbox>
          </vs-tooltip>
        </b-col>
      </b-row>
      <div class="mb-3 mt-3">
        <div class="d-flex">
          <vs-button>{{ $t('form.save') }}</vs-button>
          <vs-button @click="$emit('closeModa')" success type="button">
            {{ $t('form.abort') }}
          </vs-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import AlertEmails from './AlertEmails.vue';
import { required, numeric } from 'vuelidate/lib/validators';
import Swal from 'sweetalert2';

export default {
  props: {
    typeform: {
      type: Object,
      default: () => {
        return {};
      },
    },
    clients: {
      typeof: Array,
      default: () => {
        return [];
      },
    },
    currencies: {
      typeof: Array,
      default: () => {
        return [];
      },
    },
    rouletteFisics: {
      typeof: Array,
      default: () => {
        return [];
      },
    },
    rouletteTypes: {
      typeof: Array,
      default: () => {
        return ['EUROPEAN', 'AMERICAN'];
      },
    },
  },
  components: { Multiselect, AlertEmails },
  data() {
    return {
      numbersOfJackpot: [1, 2, 3, 4, 5],
      jackpotVersions: ['V2', 'V3', 'V4', 'V5'],
      multisAllowedRef: [50, 100, 150, 200, 300, 500, 1000],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      currenciesSelected: [],
      selectionOpen: false,
      showSpecialCalle: false,

      languages: [
        {
          name: 'Ingles',
          value: 'en',
        },
        {
          name: 'Español',
          value: 'es',
        },
        {
          name: 'Portugues',
          value: 'pt',
        },
      ],
      emailsGame: [],
    };
  },
  validations: {
    typeform: {
      name: {
        required,
      },
      logo: {
        required,
      },
      pleno: {
        required,
      },
      semipleno: {
        required,
      },
      calle: {
        required,
      },
      cuadro: {
        required,
      },
      linea: {
        required,
      },
      columna: {
        required,
      },
      docena: {
        required,
      },
      chanceSimple: {
        required,
      },
      cubre: {
        required,
      },
      providerId: {
        required,
      },
      maxBetPosition: { numeric, required },
      maxPlenosBet: { numeric },
      language: {
        required,
      },
      urlTransmision: { required },
      maxRepeatedResults: { required },
    },
  },
  methods: {
    addTag(newTag) {
      const tag = newTag;
      this.value.push(tag);
    },
    save() {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log('ERROR VALIDANDO FORMULARIO', this.typeform);
        console.log('error');
        return false;
      } else {
        if (this.typeform._id) {
          this.$http
            .put(`roulettes/${this.typeform._id}`, this.typeform)
            .then(({ data }) => {
              if (!data.ok) {
                return Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: data.ok,
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Ruleta guardado con exito',
                showConfirmButton: false,
                timer: 1500,
              });

              this.$emit('closeModa');
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.typeform.lastJackpot = 0;
          this.typeform.currenJackpotRound = 0;
          this.$http
            .post('roulettes', this.typeform)
            .then(({ data }) => {
              if (!data.ok) {
                return Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: data.msg,
                  showConfirmButton: false,
                  timer: 1500,
                });
              }

              Swal.fire({
                position: 'center',
                icon: 'success',
                title: data.msg,
                showConfirmButton: false,
                timer: 1500,
              });

              this.$emit('closeModa');
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
    handleSelection(currency) {
      const currencies = this.typeform.currencies.filter(
        (curr) => currency._id === curr._id
      );

      if (currencies.length) return;

      this.typeform.currencies.push(currency);
    },
    deleteSelection(id) {
      const updateSelection = this.typeform.currencies.filter(
        (currency) => currency._id !== id
      );

      this.typeform.currencies = updateSelection;
    },
    handleOpen() {
      this.selectionOpen = !this.selectionOpen;
    },
    handleChange() {
      const rltSelected = this.rouletteFisics.find(
        (rlt) => rlt.providerId === this.typeform.providerId
      );

      if (!rltSelected) return;

      this.showSpecialCalle = rltSelected.doubleZero;
    },
    modifyAlertEmails() {
      const { alertEmails } = this.typeform;
      const emails = alertEmails || [];
      this.emailsGame = [...emails];
      this.$refs.alertEmails.modal = true;
    },
    saveEmails() {
      this.typeform.alertEmails = [...this.emailsGame];
      this.save();
    },
  },
};
</script>

<style scoped>
.multipleSelect {
  width: 95%;
  z-index: 2;
  cursor: pointer;
}

.select-area {
  height: 39px;
  overflow-y: scroll;
  cursor: pointer;
}

.select-area::-webkit-scrollbar {
  display: none;
}

.select-area > div {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  padding: 4px;
}

.chip {
  display: flex;
  height: 30px;
  font-size: 12px;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 10px;
  background-color: #c8c8c8;
  margin-right: 5px;
  padding: 0 5px;
}

.closebtn {
  color: #888;
  font-weight: bolder;
  cursor: pointer;
  width: 10px;
  margin-left: 10px;
  font-size: 14px;
}

.closebtn:hover {
  color: #000;
}
</style>
