<template>
  <b-modal id="bv-modal-example" v-model="modal" size="lg" hide-footer>
    <template #modal-title> Importar monedas </template>
    <div>
      <form @submit.prevent="save">
        <b-row class="justify-content-center">
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label> Ruletas</label>
              <div>
                <select
                  v-model="typeform.from"
                  name="from"
                  class="form-select"
                  :class="{
                    'is-invalid': typesubmit && $v.typeform.from.$error,
                  }"
                  placeholder="Seleccione.."
                >
                  <option disabled selected>
                    {{ $t('helpers.select') }}...
                  </option>
                  <option
                    v-for="(item, index) in roulettes"
                    :key="index"
                    :value="item._id"
                  >
                    {{ item.name }}
                  </option>
                </select>
                <div
                  v-if="typesubmit && $v.typeform.from.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.typeform.from.required">
                    Este campo es requerido.
                  </span>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <div class="mb-3 mb-0">
          <div>
            <button type="submit" class="btn btn-primary">Guardar</button>
            <button
              @click="closeModa()"
              type="button"
              class="btn btn-secondary ms-1"
            >
              Cancelar
            </button>
          </div>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
// import Swal from "sweetalert2";
// import { setUserAction } from "../../../helpers/userActions/userActions";

export default {
  props: {
    roulette: {
      type: Object,
      default: () => {
        return {};
      },
    },
    roulettes: {
      typeof: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      modal: false,
      typeform: {},
    };
  },
  validations: {
    typeform: {
      from: {
        required,
      },
    },
  },
  methods: {
    save() {
      this.typesubmit = true;
      // stop here if form is invalid
      // this.$v.$touch();
      // if (this.$v.$invalid) {
      //   console.log("error");
      // } else {
      //   this.$http
      //     .put(`roulettes/${this.client._id}/import-roulettes`, this.typeform)
      //     .then(({ data }) => {
      //       if (data.ok) {
      //         Swal.fire({
      //           position: "center",
      //           icon: "success",
      //           title: data.msg,
      //           showConfirmButton: false,
      //           timer: 1500,
      //         });
      //         this.closeModa();
      //       } else {
      //         Swal.fire({
      //           position: "center",
      //           icon: "error",
      //           title: data.msg,
      //           showConfirmButton: false,
      //           timer: 1500,
      //         });
      //         this.closeModa();
      //       }
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });
      // }
    },
    closeModa() {
      this.typeform = {};
      this.modal = false;
    },
  },
};
</script>
