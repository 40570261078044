<template>
  <b-modal id="modal-alert-emails" v-model="modal" size="lg" hide-footer>
    <template #modal-title>{{
      Object.entries(game).length ? game.name : 'Juego'
    }}</template>
    <div class="my-4">
      <div
        v-for="(email, i) in emails"
        :key="i"
        class="d-flex justify-content-between align-items-center"
      >
        <div>
          Direccion: <strong>{{ email }}</strong>
        </div>
        <vs-button type="button" danger @click="() => emails.splice(i, 1)"
          >Eliminar</vs-button
        >
      </div>
      <div class="d-flex justify-content-between align-items-center mt-5">
        <vs-input
          border
          primary
          type="email"
          icon-after
          v-model="emailToAdd"
          label-placeholder="Email"
          class="w-75"
        >
          <template #icon>
            <i class="bx bxl-bitcoin"></i>
          </template>
        </vs-input>
        <vs-button type="button" primary @click="() => emails.push(emailToAdd)"
          >Añadir</vs-button
        >
      </div>
      <div class="d-flex justify-content-center">
        <vs-button type="button" @click="saveEmails">Guardar</vs-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    emails: {
      type: Array,
      default: () => ['hola', 'hi'],
    },
    game: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      modal: false,
      emailToAdd: '',
    };
  },
  methods: {
    saveEmails() {
      this.$emit('saveEmails');
    },
  },
};
</script>
