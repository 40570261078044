<template>
  <b-modal id="bv-modal-example" v-model="modal" size="lg" hide-footer>
    <template #modal-title>
      {{
        roulette.currencies ? $t('addCurrency.modify') : $t('addCurrency.add')
      }}</template
    >
    <div>
      <div>
        <div class="mb-3 mx-auto">
          <multiselect
            v-model="value"
            label="name"
            track-by="name"
            :options="currencies"
            :multiple="true"
            @tag="addTag"
          >
          </multiselect>
        </div>
        <div class="mb-3 mx-auto">
          <div class="d-flex justify-content-center">
            <vs-button type="submit" @click="save">{{
              $t('form.save')
            }}</vs-button>
            <vs-button @click="closeModa()" success type="button">{{
              $t('form.abort')
            }}</vs-button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';

export default {
  components: { Multiselect },
  props: {
    roulette: {
      type: Object,
      default: () => {
        return {};
      },
    },
    currencies: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      //!CURRENCY
      value: [],
      //
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      modal: false,
      typeform: {},
      fichas: [
        { valor: 0.1 },
        { valor: 0.5 },
        { valor: 1 },
        { valor: 5 },
        { valor: 10 },
      ],

      selectionOpen: false,
    };
  },

  methods: {
    addTag(newTag) {
      const tag = newTag;
      // this.options.push(tag)
      console.log('tag', tag);
      this.value.push(tag);
    },
    save() {
      this.typesubmit = true;

      if (!this.value.length)
        return Swal.fire('Currencies are necessary', '', 'info');

      const currencies = this.value.map((currency) => {
        return currency._id;
      });

      this.$http
        .put(`/roulettes/${this.roulette._id}/modify-currencies`, {
          currencies,
        })
        .then(({ data }) => {
          if (!data.ok) {
            return Swal.fire({
              title: 'Error al agregar monedas',
              icon: 'error',
            });
          }

          return Swal.fire({
            title: 'Monedas agregadas',
            icon: 'success',
          });
        })
        .catch((err) => {
          console.log(err);
        });

      //TODO: Enviar las monedas al backend. PARAMS: roulette: id. body: [idCurrencies]
    },
    defaultValue(value) {
      let roundDuration =
        value.target.options[value.target.options.selectedIndex].getAttribute(
          'data-roundDuration'
        );

      this.typeform.roundDuration = roundDuration;
    },
    closeModa() {
      this.typeform = {};
      this.modal = false;
    },
    handleOpen() {
      this.selectionOpen = !this.selectionOpen;
    },
    handleSelection(currency) {
      const isItAdded = this.roulette.currencies.filter(
        (curr) => curr._id === currency._id
      );

      if (!isItAdded.length) {
        this.roulette.currencies.push(currency);
      }
    },
    deleteSelection(id) {
      const updateSelection = this.roulette.currencies.filter(
        (currency) => currency._id !== id
      );
      this.roulette.currencies = updateSelection;
    },
  },
  watch: {
    roulette(newValue) {
      this.value = [];

      const { currencies } = newValue;

      if (!currencies) return;

      const values = currencies.map((currency) => ({
        name: currency.name,
        _id: currency._id,
      }));
      this.value.push(...values);
    },
  },
};
</script>

<style>
.multiselect__tag-icon:hover {
  background-color: var(--bs-secondary);
}
</style>
