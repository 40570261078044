import { menuItems } from '../components/menu/menu';

export const filterMenu = (target) => {
  const items = menuItems.filter((item) => {
    if (item.label === target) {
      return item;
    }
  });
  return items;
};
